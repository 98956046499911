import { useEffect } from 'react';

import { AudienceControlMsgSenderProvider } from '../../components/Audience/AudienceControlProviders';
import { BotProvider } from '../../components/Bot';
import { BroadcastProvider } from '../../components/Broadcast/BroadcastProvider';
import { CohostPositionManagerProvider } from '../../components/Cohost/CohostPositionManagerProvider';
import { ConfirmCancelModalProvider } from '../../components/ConfirmCancelModalContext';
import { ContextMenuProvider } from '../../components/ContextMenu/Context';
import { CrowdFramesProvider } from '../../components/CrowdFrames';
import { useCreateDefaultVideoStreamMixer } from '../../components/Device/video-stream-mixer';
import { ExperienceMetricsProvider } from '../../components/ExperienceMetrics';
import { ExperienceScoreProvider } from '../../components/ExperienceScore';
import {
  useFirebaseContext,
  useInitFirebase,
  useIsFirebaseConnected,
} from '../../components/Firebase';
import { FirebaseLatencyProvider } from '../../components/FirebaseLatency/Provider';
import { FrameRateMeasure } from '../../components/FrameRateMeasure/FrameRateMeasure';
import { BlockProviders } from '../../components/Game/Blocks';
import { BlockOutputsClearer } from '../../components/Game/Blocks/Common/block-outputs';
import {
  GamePlayHostVideo,
  OndGamePlayOverlay,
} from '../../components/Game/Blocks/Common/GamePlay';
import { GamePlayUIConfigurationProvider } from '../../components/Game/Blocks/Common/GamePlay/GamePlayUIConfigurationProvider';
import { GameSessionPreconfigProvider } from '../../components/Game/Blocks/Common/GamePlay/GameSessionPreconfigProvider';
import { BlockLifecycleRulesEvaluatorProvider } from '../../components/Game/Blocks/Common/LifecycleRules/BlockLifecycleRulesEvaluator';
import { GameSessionBundle } from '../../components/Game/GameSessionBundle';
import { GameStreamingStatusProvider } from '../../components/Game/GameStreamingStatusProvider';
import { OnDGameControlProvider } from '../../components/Game/OndGameControl';
import { PostGameProvider } from '../../components/Game/PostGame/Provider';
import { PreGameProvider } from '../../components/Game/PreGame/Provider';
import {
  GameLogMonitorBrandsBlocks,
  GameLogMonitorScoreboard,
  GameLogMonitorSession,
  GameLogMonitorTeamCreation,
  GameLogMonitorTeamNames,
  GameLogMonitorUserJoined,
  GameLogSynchronizeCommonProperties,
} from '../../components/GameLog/GameLogComponents';
import { MiddlePanel } from '../../components/Layout';
import { LayoutAnchorProvider } from '../../components/LayoutAnchors/LayoutAnchors';
import { LobbyProvider } from '../../components/Lobby';
import { MediaControls } from '../../components/MediaControls';
import { MusicPlayerProvider } from '../../components/MusicPlayer/Context';
import {
  NotificationContextProvider,
  useNotificationDataSourceRedux,
} from '../../components/Notification/Context';
import {
  PairingGameControl,
  PairingGameProvider,
} from '../../components/Pairing';
import { PersistentPointsProvider } from '../../components/PersistentPoints/Provider';
import { PixelFxProvider } from '../../components/PixelFx/PixelFxProvider';
import { PixelFxSceneProvider } from '../../components/PixelFx/PixelFxSceneProvider';
import { useMyInstance } from '../../components/Player';
import { ProvidersList } from '../../components/ProvidersList';
import { RemoteRefreshProvider } from '../../components/RemoteRefresh/RemoteRefreshProvider';
import { useStreamSessionRecovery } from '../../components/Session';
import { SFXProvider } from '../../components/SFX';
import { useInitStage } from '../../components/Stage';
import {
  SwitchNotice,
  SwitchNoticeProvider,
} from '../../components/SwitchNotice';
import { TeamRandomizerProvider } from '../../components/TeamRandomizer';
import { TeamSizeContextProvider } from '../../components/TeamSizeControl/Context';
import { maxTeamMembers } from '../../components/TeamSizeControl/utils';
import { TownhallProvider } from '../../components/Townhall';
import {
  useUser,
  useUserContext,
  useUserStates,
} from '../../components/UserContext';
import {
  useMyClientId,
  useMyClientType,
  useParamVenueId,
  useVenue,
  VenueBootstrap,
  VenueContextProvider,
  VenueSetup,
} from '../../components/Venue';
import { useFirebaseVenueSyncing } from '../../components/Venue/useFirebaseVenueSyncing';
import { VenueOrgLogoAverageColorProvider } from '../../components/VenueOrgLogoAverageColor/VenueOrgLogoAverageColorProvider';
import { VideoEffectsSettingsDummyStore } from '../../components/VideoEffectsSettings/Storage';
import { AdHocVOMsgReceiverProvider } from '../../components/VoiceOver/AdHocVOMsgProviders';
import { LocalizedVoiceoversRTCPublisherProvider } from '../../components/VoiceOver/LocalizedVoiceOvers';
import { SubtitlesManagerProvider } from '../../components/VoiceOver/SubtitlesManagerProvider';
import { useAudienceRTCServiceMap } from '../../components/WebRTC/hooks/useInitRTCService';
import { RTCServiceContextProvider } from '../../components/WebRTC/RTCServiceContext';
import { useEnsureUnlockedAudioContext } from '../../hooks/useEnsureUnlockedAudioContext';
import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useUpdateLoggerWithStreamSessionId } from '../../hooks/useInitLogger';
import { useInitReduxSlice } from '../../hooks/useInitRedux';
import { useInitSentryContext } from '../../hooks/useInitSentryContext';
import {
  useInitGameSession,
  useInitStreamSession,
} from '../../hooks/useInitSession';
import { useInstance } from '../../hooks/useInstance';
import { useIsController } from '../../hooks/useMyInstance';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useUserWatcher } from '../../hooks/useUserWatcher';
import {
  getAudioContext,
  getEchoCancelledAudioDestination,
} from '../../services/audio/audio-context';
import { type ClientId } from '../../services/crowd-frames';
import { ClientType } from '../../types/user';
import { CloudUserRequired } from './CloudUserRequired';
import { LaunchOnDGameControl } from './LaunchOnDGameControl';
import { useInitCloudOnDGameHosting } from './useInitCloudOnDGameHosting';

const isDebugging = getFeatureQueryParamArray('debug-tools') !== 'disabled';

const CloudHost = (): JSX.Element | null => {
  const user = useUser();
  const { updateUserStates } = useUserContext();
  const clientId = useMyClientId();
  const clientType = useMyClientType();
  const me = useMyInstance();
  const rtcServiceMap = useAudienceRTCServiceMap(clientId);
  const { joined } = useUserStates();
  const firebaseConnected = useIsFirebaseConnected();
  const [venue] = useVenue();
  const notificationDataSource = useNotificationDataSourceRedux();
  const isController = true;

  usePageTitle(user, venue);
  useInitCloudOnDGameHosting(clientId);
  useUserWatcher(clientId, clientType, user);
  useUpdateLoggerWithStreamSessionId();
  useInitFirebase(!!user.id && !!venue.id);
  useInitReduxSlice(clientId, venue.id);
  useEnsureUnlockedAudioContext();
  useInitSentryContext(venue.id);
  useInitStreamSession(isController);
  useInitGameSession({
    isController,
    initialSetup: useInstance(() => ({ isLiveGame: false })),
  });
  useInitStage();
  useFirebaseVenueSyncing(true);
  useStreamSessionRecovery(true);

  useEffect(() => {
    updateUserStates({ joined: true });
    return () => {
      updateUserStates({ joined: false });
    };
  }, [updateUserStates]);
  const { svc, emitter } = useFirebaseContext();
  const registeredAsController = useIsController();
  const isReady =
    joined &&
    firebaseConnected &&
    !!me &&
    // The rtcServices auto join the channel with role host/audience based on
    // this flag, the return value of _useIsController_ may not reflect the
    // value in realtime because of the async update of Firebase state. And
    // the following actions such as a recovery resume may be failed
    // (pubish failure) because of the incorrect _audience_ role. For Cloud
    // Hosting, this is required to be true.
    registeredAsController;

  if (!user.id) return null;

  const providers = [
    <ExperienceScoreProvider />,
    <SFXProvider />,
    <ConfirmCancelModalProvider />,
    <SwitchNoticeProvider />,
    <LayoutAnchorProvider />,
    <PixelFxProvider />,
    <RTCServiceContextProvider
      readyToJoin={isReady}
      rtcServiceMap={rtcServiceMap}
    />,
    <CrowdFramesProvider
      liteModeEnabled={false}
      userId={user.id}
      myClientId={clientId as ClientId}
    />,

    <NotificationContextProvider datasource={notificationDataSource} />,
    <LobbyProvider ready={isReady} svc={svc} />,
    <PairingGameProvider svc={svc} />,
    <MusicPlayerProvider
      audioContextGetter={getAudioContext}
      echoCancelledAudioDestinationGetter={getEchoCancelledAudioDestination}
    />,
    <TeamRandomizerProvider venueId={venue.id} />,
    <TeamSizeContextProvider
      venueId={venue.id}
      defaultMaxMembers={maxTeamMembers}
    />,
    <RemoteRefreshProvider />,
    <ContextMenuProvider />,
    <SubtitlesManagerProvider venueId={venue.id} />,
    <OnDGameControlProvider ready={isReady} svc={svc} emitter={emitter} />,
    <PreGameProvider />,
    <PostGameProvider />,
    <GamePlayUIConfigurationProvider />,
    <TownhallProvider ready={isReady} svc={svc} />,
    <GameSessionPreconfigProvider />,
    <ExperienceMetricsProvider isReady={isReady} />,
    <FirebaseLatencyProvider
      isReady={isReady}
      clientId={clientId as ClientId}
      firebaseService={svc}
    />,
    <BotProvider />,
    <GameStreamingStatusProvider />,
    <AdHocVOMsgReceiverProvider venueId={venue.id} />,
    <CohostPositionManagerProvider ready={isReady} svc={svc} />,
    <AudienceControlMsgSenderProvider venueId={venue.id} />,
    <LocalizedVoiceoversRTCPublisherProvider
      venueId={venue.id}
      readyToJoin={isReady}
    />,
    <BlockLifecycleRulesEvaluatorProvider />,
    <VenueOrgLogoAverageColorProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <VenueSetup />
      <GameLogSynchronizeCommonProperties venueId={venue.id} />
      <FrameRateMeasure />
      <GameLogMonitorUserJoined />
      <GameLogMonitorScoreboard />
      <GameLogMonitorBrandsBlocks />
      <GameLogMonitorTeamCreation />
      <GameLogMonitorTeamNames />
      <GameLogMonitorSession />
      <BlockOutputsClearer venueId={venue.id} svc={svc} />
      <div className='h-full w-full'>
        {isReady && (
          <>
            {isDebugging && <MediaControls />}
            <PairingGameControl />
            <GamePlayHostVideo />
            <ProvidersList
              providers={[
                <BroadcastProvider ready={isReady} />,
                <PersistentPointsProvider userId={user.id} />,
                <PixelFxSceneProvider />,
              ]}
            >
              <MiddlePanel className='z-30' />
              <BlockProviders>
                <GameSessionBundle />
              </BlockProviders>
            </ProvidersList>
          </>
        )}
        <OndGamePlayOverlay />
        {isReady && <LaunchOnDGameControl id={clientId} />}
        <SwitchNotice />
      </div>
    </ProvidersList>
  );
};

// eslint-disable-next-line import/no-default-export
export default function CloudHostWrapper(): JSX.Element {
  const mixer = useCreateDefaultVideoStreamMixer();
  const vesStore = useInstance(() => new VideoEffectsSettingsDummyStore());
  return (
    <VenueContextProvider paramVenueId={useParamVenueId()}>
      {(venueId) => {
        return (
          <VenueBootstrap
            venueId={venueId}
            clientType={ClientType.Host}
            providers={[<CloudUserRequired />]}
            mixer={mixer}
            vesStore={vesStore}
          >
            <CloudHost />
          </VenueBootstrap>
        );
      }}
    </VenueContextProvider>
  );
}
