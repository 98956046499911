import { useEffect } from 'react';

import { useIsFirebaseConnected } from '../../components/Firebase';
import { useGameHostingAPI } from '../../components/Game/GameHostingProvider';
import { useUserStates } from '../../components/UserContext';

export function useInitCloudOnDGameHosting(clientId: string): void {
  const { joined } = useUserStates();
  const api = useGameHostingAPI();
  const connected = useIsFirebaseConnected();
  useEffect(() => {
    if (!joined || !connected) return;
    api.register(clientId, { asController: true });
  }, [api, connected, joined, clientId]);
}
