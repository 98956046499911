import { type ReactNode } from 'react';

import { useUser } from '../../components/UserContext';
import { useQueryParam } from '../../hooks/useQueryParam';

export function TokenRequired(props: { children: ReactNode }): JSX.Element {
  const token = useQueryParam('token');

  if (!token) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <div className='text-white text-5xl'>Cloud hosting token not found</div>
      </div>
    );
  }

  return <>{props.children}</>;
}

function UserRequired(props: { children: ReactNode }): JSX.Element | null {
  const token = useQueryParam('token');
  if (!token) throw new Error('Cloud hosting token is required');
  const user = useUser({ init: true, token });

  if (!user) return null;

  return <>{props.children}</>;
}

export function CloudUserRequired(props: {
  children?: ReactNode;
}): JSX.Element | null {
  return (
    <TokenRequired>
      <UserRequired>{props.children}</UserRequired>
    </TokenRequired>
  );
}
